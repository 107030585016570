<template>
    <ImageGridContent v-editable="blok" :data="imageGridContentData" />
</template>

<script setup lang="ts">
import ImageGridContentType from '~/types/ImageGridContentType';
import ButtonType from '~/types/ButtonType';
import ImageType from '~/types/ImageType';
import ImageGridItemType from '~/types/ImageGridItemType';
import LinkType from '~/types/LinkType';
import ImageGridContent from '~/components/page-building/image-grid-content/ImageGridContent.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const items = props.blok.items
    ? props.blok.items.map((item: any) => {
          return {
              image: {
                  title: item.image.title,
                  alt: item.image.alt,
                  src: item.image.filename,
              } as ImageType,
              showHoverState: item.show_hover_state,
              hoverTagTitle: item.hover_tag_title,
              hoverTitle: item.hover_title,
              hoverContent: richTextToHtml(item.hover_content) as string,
              hoverLink: item.hover_link
                  ? ({
                        label: item.hover_link_label,
                        url: item.hover_link.cached_url,
                    } as LinkType)
                  : null,
              vEditable: item,
          } as ImageGridItemType;
      })
    : [];

const buttons = props.blok.buttons
    ? props.blok.buttons.map((button: any) => {
          return {
              label: button.label,
              style: button.style,
              url: button.link.cached_url,
              vEditable: button,
          } as ButtonType;
      })
    : [];

const imageGridContentData = computed<ImageGridContentType>(() => {
    return {
        heading: props.blok.heading,
        content: richTextToHtml(props.blok.content) as string,
        buttons,
        items,
        reverseLayout: props.blok.reverse_layout,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
    };
});
</script>
